<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid pt-2">
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Title"
        [placeholder]="'Enter Title'"
        [control]="formGroup?.controls?.title"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-6">
      <!-- <app-entity-category-tree-selector
      [label]="'Owner'"
      [categoryList]="categoryList"
      [placeholder]="'Search Entities'"
      [control]="formGroup?.controls?.owner"
      [selectedEntities]="[data?.owner]"
      [multi]="false"
      [viewMode]="fieldViewMode"
      [dropDown]="true"
      >
    </app-entity-category-tree-selector> -->
      <app-user-and-group-selector
        [label]="'Owner'"
        [placeholder]="'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>

    <div class="col-12 md:col-6">
      <app-date-input
        [label]="'Duration From'"
        [placeholder]="'2022-11-22'"
        [name]="'durationFrom'"
        [showIcon]="true"
        [control]="formGroup?.controls?.durationFrom"
        [viewMode]="fieldViewMode"
        [minDate]="minDate"
        [maxDate]="formGroup?.controls?.durationTo?.value || maxDate || null"
      ></app-date-input>
    </div>

    <div class="col-12 md:col-6">
      <app-date-input
        [label]="'Duration To'"
        [placeholder]="'2022-11-22'"
        [name]="'durationTo'"
        [showIcon]="true"
        [control]="formGroup?.controls?.durationTo"
        [viewMode]="fieldViewMode"
        [minDate]="formGroup?.controls?.durationFrom?.value || minDate || null"
        [maxDate]="maxDate"
      ></app-date-input>
    </div>

    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
