import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseForm, EntityTypeFieldDto, FrameworkException, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { min } from 'lodash-es';

@Component({
  selector: 'app-exception-form',
  templateUrl: './exception-form.component.html',
  styleUrls: ['./exception-form.component.scss'],
})
export class ExceptionFormComponent extends BaseForm<FrameworkException> implements OnInit {
  categoryList = [EntityTypeFieldDto.CategoryEnum.Responsibility];
  _minDate = null;
  @Input() set minDate(value) {
    this._minDate = value;
  }
  get minDate() {
    return this._minDate;
  }
  @Input() maxDate = null;

  constructor(
    public viewModeService: ViewModeService,
    private route: ActivatedRoute
  ) {
    super(viewModeService, ModuleKeywords.Exception);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    if (this.viewModeService.viewMode == 'edit' && data.durationFrom) {
      const date = new Date(Date.parse(data.durationFrom));
      this.minDate = min([this.minDate, date]);
    }
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      title: new FormControl(null, Validators.required),
      description: new FormControl(null),
      // approvers: new FormControl(null , Validators.required),
      owner: new FormControl(null, Validators.required),
      durationFrom: new FormControl(null, Validators.required),
      durationTo: new FormControl(null, Validators.required),
      targetCode: new FormControl(null),
      type: new FormControl(null),
    });
  }
}
